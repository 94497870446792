
import { defineComponent, reactive, toRefs } from "vue";
import item from "../item.vue";

export default defineComponent({
  components: { item },
  props: {
    title: String,
    list: Array,
    width: String,
    tag: String,
    checked: { type: [Object, String], required: true },
    required: Boolean,
  },
  setup(props, cxt) {
    const state = reactive({
      value: props.checked,
      other: "",
      changeCheck() {
        cxt.emit("update:checked", this.value);
      },
      inputOther() {
        cxt.emit("update:checked", this.other);
      },
    });

    return {
      ...toRefs(state),
    };
  },
});
