<template>
  <div class="every_item">
    <div class="subtitle">{{ title }}</div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "item",
  props: {
    title: {
      type: String
    }
  },
});
</script>

<style lang="scss" scoped>
$bgColor: #484848;
$baseColor: #00dd1b;
.item {
  display: inline-block;
  line-height: 40px;
}
</style>