
import { defineComponent } from "vue";

export default defineComponent({
  name: "item",
  props: {
    title: {
      type: String
    }
  },
});
