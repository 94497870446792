<template>
  <item :title="title">
    <div class="item_chose">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="item"
        :style="{ width }"
      >
        <input
          type="radio"
          :value="item"
          v-model="value"
          @change="changeCheck"
          :id="tag + index"
        />
        <label :for="tag + index"><span></span> {{ item.type || item }}</label>
      </div>
    </div>
    <input
      type="text"
      v-if="value === '其他'"
      v-model="other"
      placeholder="输入其他选择"
      @input="inputOther"
    />
  </item>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import item from "../item.vue";

export default defineComponent({
  components: { item },
  props: {
    title: String,
    list: Array,
    width: String,
    tag: String,
    checked: { type: [Object, String], required: true },
    required: Boolean,
  },
  setup(props, cxt) {
    const state = reactive({
      value: props.checked,
      other: "",
      changeCheck() {
        cxt.emit("update:checked", this.value);
      },
      inputOther() {
        cxt.emit("update:checked", this.other);
      },
    });

    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
$bgColor: #484848;
$baseColor: #00dd1b;
.item {
  display: inline-block;
  line-height: 40px;
}
input[type="radio"] {
  width: 12px;
  height: 12px;
  opacity: 0;
}
label {
  position: relative;
  span {
    position: absolute;
    width: 16px;
    height: 16px;
    border: 1px solid #999;
    top: 1px;
    left: -20px;
    border-radius: 50%;
  }
}
input:checked + label {
  span {
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 1px;
      width: 60%;
      height: 25%;
      border-right: 4px solid $baseColor;
      border-top: 4px solid $baseColor;
      transform: rotate(135deg);
    }
  }
}

input[type="text"] {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  padding: 5px;
}
</style>