<template>
  <div class="main_content">
    <div class="title">{{ title }}</div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "main-content",
  props: {
    title: String,
  },
});
</script>

<style lang="scss" scoped>
.title {
  padding: 15px 20px;
  background-color: #484848;
}
.content {
  padding: 10% 10px;
}
</style>