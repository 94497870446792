<template>
  <main-content title="高侍选型">
    <input type="text" placeholder="企业名称*" v-model="form.company" />
    <input type="text" placeholder="联系人*" v-model="form.username" />
    <input type="number" placeholder="电话号码*" v-model="form.phone" />
    <radio
      :list="profList"
      width="33%"
      title="行业选择(请选择最主要的一个)"
      tag="profession"
      v-model:checked="form.field"
    />
    <div class="btn">
      <button @click="toChoose">去选型</button>
    </div>

    <div class="tip">{{ tip }}</div>
  </main-content>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import mainContent from "@/components/mainContent.vue";
import Radio from "@/components/form/radio.vue";

interface formProps {
  company: string;
  username: string;
  phone: number | string;
  field: string;
}

interface stateProps {
  form: formProps;
  tip: string;
  toChoose: () => void;
}

const ifMainValueIsUndefindTip = {
  company: "企业名称",
  username: "联系人",
  phone: "电话号码",
  field: "行业选择",
};

export default defineComponent({
  name: "index",
  components: {
    mainContent,
    Radio,
  },
  setup() {
    const profList = ref([
      "医疗器械",
      "3C电子产品",
      "珠宝制造",
      "航天航空",
      "汽车制造",
      "模具制造",
      "教育应用",
      "其他",
    ]);

    const router = useRouter();

    const initState = {
      company: "",
      username: "",
      phone: "",
      field: '',
    };

    const { company, username, phone, field } = JSON.parse(
      sessionStorage.getItem("form") || JSON.stringify(initState)
    );

    const state: stateProps = reactive({
      form: {
        company,
        username,
        phone,
        field,
      },
      tip: "",
      async toChoose() {
        for (let i in state.form) {
          if (!state.form[i]) {
            state.tip = ifMainValueIsUndefindTip[i] + "不能为空";
            return;
          }
        }
        sessionStorage.setItem("form", JSON.stringify(state.form));
        router.push({ path: "/choose" });
      },
    });

    return {
      profList,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: center;
  margin-top: 12vh;
  button {
    width: 50vw;
    max-width: 300px;
  }
}
</style>